import React from "react"
import { Flex, Text, Box } from "@chakra-ui/core"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import AppleSVG from "./apple-app-store.svg"

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      whatsapp: file(relativePath: { eq: "whatsapp.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return (
    <Flex mt={10} direction="column" mb={4}>
      <Flex
        align="center"
        justify="space-between"
        direction={["column", "row"]}
      >
        <Box
          w="260px"
          as={Link}
          to="https://play.google.com/store/apps/details?id=com.laudiumtoday"
          mr={5}
        >
          <Img fluid={data.file.childImageSharp.fluid} />
        </Box>
        <Box
          w="260px"
          as={Link}
          to="https://apps.apple.com/us/app/laudium-today/id1538645970"
          mr={5}
          p={6}
        >
          <img src={AppleSVG} width="100%" />
        </Box>
        <Box w="260px" as={Link} to="https://wa.me/27798021142" mr={5}>
          <Img fluid={data.whatsapp.childImageSharp.fluid} />
        </Box>
      </Flex>
      <Text
        textAlign="center"
        color="Gray"
      >{`© Copyright ${new Date().getFullYear()} Laudium Today.`}</Text>
    </Flex>
  )
}

export default Footer
