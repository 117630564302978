import React, { useRef } from "react"
import {
  Flex,
  Box,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  IconButton,
} from "@chakra-ui/core"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { AiOutlineMenu } from "react-icons/ai"
import useWindowSize from "../../hooks/useWindowSize"
import { maxMobileSize } from "../../constants"

const navigationItems = [
  {
    id: 0,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "About Us",
    link: "/about",
  },
  {
    id: 2,
    name: "News",
    link: "/articles",
  },
  {
    id: 3,
    name: "Notices",
    link: "/notices",
  },
  {
    id: 4,
    name: "Classifieds",
    link: "/classifieds",
  },
  {
    id: 5,
    name: "Directory",
    link: "/listings",
  },
  {
    id: 6,
    name: "Contact Us",
    link: "/contact",
  },
  {
    id: 7,
    name: "Privacy",
    link: "/privacy",
  },
  {
    id: 8,
    name: "Disclaimer",
    link: "/disclaimer",
  },
]

function Header({ ...rest }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 430, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const windowSize = useWindowSize()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  return (
    <Flex
      align="center"
      justify="space-between"
      width="100%"
      maxW="1280px"
      position="fixed"
      top="0"
      padding={4}
      mb={6}
      zIndex="1"
      backgroundColor="white"
      {...rest}
    >
      <Box w="430px" as={Link} to="/" mr={5}>
        <Img fluid={data.file.childImageSharp.fluid} />
      </Box>
      {windowSize.width > maxMobileSize ? (
        <Flex>
          <nav>
            {navigationItems.map(navItem => (
              <Box
                key={`link-${navItem.id}`}
                as={Link}
                to={navItem.link}
                m={3}
                paddingY={3}
              >
                {navItem.name}
              </Box>
            ))}
          </nav>
        </Flex>
      ) : (
        <Flex>
          <IconButton
            ml={4}
            mt={-3}
            ref={btnRef}
            onClick={onOpen}
            aria-label="Menu"
            icon={AiOutlineMenu}
            fontSize="35px"
          />
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Menu</DrawerHeader>

              <DrawerBody>
                <nav>
                  <Flex direction="column">
                    {navigationItems.map(navItem => (
                      <Box
                        key={`mobile-link-${navItem.id}`}
                        as={Link}
                        to={navItem.link}
                        m={3}
                      >
                        {navItem.name}
                      </Box>
                    ))}
                  </Flex>
                </nav>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
      )}
    </Flex>
  )
}

export default Header
