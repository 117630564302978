import React from "react"
import { Text } from "@chakra-ui/core"

function H1({ as = "h1", ...rest }) {
  return (
    <Text
      fontWeight="bold"
      fontSize="5xl"
      lineHeight="shorter"
      textTransform="capitalize"
      as={as}
      {...rest}
    />
  )
}

function H2({ as = "h2", ...rest }) {
  return <Text fontWeight="bold" fontSize="2xl" as={as} {...rest} />
}

function H3({ as = "h3", ...rest }) {
  return <Text fontWeight="bold" fontSize="md" as={as} {...rest} />
}

function H4({ as = "h4", ...rest }) {
  return <Text fontWeight="bold" fontSize="xs" as={as} {...rest} />
}

function Caption({ ...rest }) {
  return <Text fontWeight="light" fontSize="xs" {...rest} />
}

function B1({ ...rest }) {
  return <Text fontWeight="bold" fontSize="5xl" {...rest} />
}

function B2({ ...rest }) {
  return <Text fontWeight="normal" fontSize="xl" {...rest} />
}

function B3({ ...rest }) {
  return <Text fontWeight="bold" fontSize="5xl" {...rest} />
}

function B4({ ...rest }) {
  return <Text fontWeight="500" fontSize="md" lineHeight="short" {...rest} />
}

function ButtonText({ ...rest }) {
  return <Text fontWeight="bold" fontSize="md" {...rest} />
}

export { H1, H2, H3, H4, Caption, B1, B2, B3, B4, ButtonText }
