import React from "react"
import { Flex } from "@chakra-ui/core"
import Header from "./Header"
import Footer from "./Footer"

function Layout({ children, noPadding, ...rest }) {
  return (
    <Flex
      minH="100vh"
      w="100vw"
      align="center"
      justify="flex-start"
      direction="column"
      bg="white"
      color="black"
      position="relative"
      {...rest}
    >
      <Header />
      <Flex
        flex={1}
        mt={[12, 12, 12, 24]}
        direction="column"
        maxW="1280px"
        w="100%"
        paddingX={noPadding ? 0 : [5, 20]}
        paddingTop={4}
      >
        {children}
      </Flex>
      <Footer />
    </Flex>
  )
}

export default Layout
